<template>
    <div class="row">
        <Button icon="pi pi-plus-circle" class="p-button-primary p-button-sm m-2" label="Nuevo expediente"
            @click="nuevoservicio()" />
    </div>
    <div class="row">
        <div class="col-md-4">
            <SelectButton v-model="opcionseleccionada" :options="options" />
        </div>
        <div class="col-md-2">
            <div class="row">
                <div v-if="opcionseleccionada=='Activos'">
                    <b>Facturas: </b>&nbsp;&nbsp;
                    <Button @click="obtenerExpedientesFact('factura gris', mievento)" class="Gris" label="  " />&nbsp;&nbsp;
                    <Button @click="obtenerExpedientesFact('factura rojo', mievento)" class="Rojo" label="  " />&nbsp;&nbsp;
                    <Button @click="obtenerExpedientesFact('factura amarillo', mievento)" class="Amarillo" label="  " />&nbsp;&nbsp;
                    <Button @click="obtenerExpedientesFact('factura verde', mievento)" class="Verde" label="  " />
                </div>
            </div>
            <div class="row">
                <div v-if="opcionseleccionada=='Activos' || opcionseleccionada=='Finalizados'">
                    <b>Presupuestos: </b>&nbsp;&nbsp;
                    <Button @click="obtenerExpedientesFact('presupuesto gris', mievento)" class="Gris" label="  " />&nbsp;&nbsp;
                    <Button @click="obtenerExpedientesFact('presupuesto roja', mievento)" class="Rojo" label="  " />&nbsp;&nbsp;
                    <Button @click="obtenerExpedientesFact('presupuesto naranja', mievento)" class="Naranja" label="  " />&nbsp;&nbsp;
                    <Button @click="obtenerExpedientesFact('presupuesto verde', mievento)" class="Verde" label="  " />&nbsp;
                </div>
            </div>
        </div>
        <div class="col-md-1">
            <div class="custom-control custom-checkbox">
                <input class="custom-control-input" type="checkbox" @change="obtenerExpedientes(mievento)" id="urgente"
                    v-model="urgente">
                <label for="urgente" class="custom-control-label">Urgente</label>
            </div>
        </div>
        <div class="col-md-5">
            <div class="row">
                <Dropdown scrollHeight="400px" showClear v-model="compsele" :options="companyias" filter
                    optionLabel="nombre" placeholder="Filtro Compañia">
                    <template #option="slotProps">
                        <div class="codigo">{{ slotProps.option.nombre }}&nbsp;<span class="badge badge-primary">{{
                                slotProps.option.prefijo }}</span>&nbsp;<span class="badge badge-secondary">{{
                                slotProps.option.encurso }}</span>&nbsp;<span class="badge badge-success">{{
                                slotProps.option.facturar }}</span></div>
                    </template>
                </Dropdown>&nbsp;&nbsp;
                <Dropdown scrollHeight="400px" showClear v-model="tipodanoseleccionado" :options="tiposdano" filter
                    optionLabel="danyo" placeholder="Tipo daño">

                </Dropdown>&nbsp;&nbsp;
                <Dropdown scrollHeight="400px" showClear v-model="tiposservicioseleccionado" :options="tiposservicio"
                    filter optionLabel="nombre" placeholder="Tipos servicio">
                </Dropdown>
            </div>
            <p></p>
            <div class="row">
                <Dropdown scrollHeight="400px" showClear v-model="estadopenselec" :options="estadospendientes" filter
                    optionLabel="nombre" placeholder="Estado pendiente">
                </Dropdown>&nbsp;&nbsp;
                <Dropdown scrollHeight="400px" showClear v-model="tramitadoreseleccionado" :options="tramitadores"
                    filter optionLabel="nombre" placeholder="Tramitador">
                </Dropdown>&nbsp;&nbsp;
                <Dropdown scrollHeight="400px" showClear v-model="operarioseleccionado" :options="operarios"
                    filter optionLabel="nombre" placeholder="Operario">
                </Dropdown>
            </div>
            <p></p>
            <div class="row">
                <Dropdown scrollHeight="400px" showClear v-model="satisfaccionselec" :options="satisfacciones" filter
                    optionLabel="nombre" placeholder="Nivel satisfacción">
                </Dropdown>
            </div>
        </div>
    </div>
    <div :class="miclasee" :style="difuminado">
        <tabla-datos v-if="this.opcionseleccionada != 'Finalizados'" :exped="true" :botoncrear="1" :columnas="columnas"
            :datos="expedientes" :filtros="filtros" :filtrosBuscador="filtrosBuscador" :total="total"
            @quieroDatos="obtenerExpedientes($event); mievento = $event;">
        </tabla-datos>
        <tabla-datos v-if="this.opcionseleccionada == 'Finalizados'" :facturacion="true" :exped="true" :botoncrear="1"
            :columnas="columnasfinalizados" :datos="expedientes" :filtros="filtros" :filtrosBuscador="filtrosBuscador"
            :total="total" @quieroDatos="obtenerExpedientes($event); mievento = $event;">
        </tabla-datos>
    </div>
    <Dialog v-model:visible="visible" modal header="Datos del siniestro">
        <p>
            <b>COMPAÑIA: </b>{{ datosbasicosamostrar.compania.nombre }}<br>
            <b>Nº EXPEDIENTE: </b>{{ datosbasicosamostrar.codigo }}<br>
            <b>DESCRIPCIÓN SINIESTRO: </b>{{ datosbasicosamostrar.danos }}<br>
            <b>NOMBRE: </b>{{ datosbasicosamostrar.asegurado.nombre }}<br>
            <b>DOMICILIO: </b>{{ datosbasicosamostrar.asegurado.direccion }}<br>
            <b>POBLACIÓN: </b>{{ datosbasicosamostrar.poblacion }}<br>
            <b>PROVINCIA: </b>{{ datosbasicosamostrar.provincia }}<br>
            <b>CÓDIGO POSTAL: </b>{{ datosbasicosamostrar.cp }}<br>
            <b>PERSONA CONTACTO: </b>{{ datosbasicosamostrar.clientes.asegurado.persona_contacto }}<br>
            <b>TELÉFONOS: </b>{{ datosbasicosamostrar.asegurado.telefonos[0] + " - " +
            datosbasicosamostrar.asegurado.telefonos[1] + " - " + datosbasicosamostrar.asegurado.telefonos[2] }}<br>
            <b>NIF: </b>{{ datosbasicosamostrar.asegurado.nif }}
            <b>POLIZA: </b>{{ datosbasicosamostrar.asegurado.poliza }}
        </p>
    </Dialog>
</template>
<script>
import { PwgsApi } from '../../../services/PwgsApi';
import TablaDatos from '../../TablaDatos.vue';
import { FilterMatchMode } from 'primevue/api';
import SelectButton from 'primevue/selectbutton';
import { defineAsyncComponent } from 'vue';
import Dropdown from 'primevue/dropdown';
import Dialog from 'primevue/dialog';
export default {
    props: ['selectcompany','companyias','propsactivo'],
    components: {  
        "tabla-datos": TablaDatos,
        SelectButton,Dropdown,Dialog
    },
    data() {
        return {
            satisfaccionselec:'',
            satisfacciones:[],
            operarios:'',
            difuminado:'',
            lazy: true,
            expedientes: [],
            campo: "datos_basicos.color_factura",
            tittle: "hola",
            compsele: '',
            urgente: '',
            visible:false,
            estadopenselec: '',
            estadospendientes: [],
            tiposservicio: '', 
            tiposservicioseleccionado:'',
            parametros: null,
            tipodanoseleccionado: '',
            tiposdano: '',
            miclasee:'',
            tramitadores: '',
            buscador: '',
            mievento: [],
            datosbasicosamostrar:[],
            tramitadoreseleccionado:'',
            operarioseleccionado:'',
            colorseleccionado:'Gris',
            opcionseleccionada:'Activos',
            filtrosBuscador: ['codigo_servicio'],
            filtros: {
                'codigo': { value: "", matchMode: FilterMatchMode.CONTAINS },
            },
            options: ['Activos', 'Finalizados', 'Eliminados', 'Todos'],
            opcionescolor: ['Gris', 'Verde', 'Rojo','Todos'],
            columnas: [
                {
                    field: 'icono',
                    acciones: [
                        {
                            link: true,
                            tipo: 'default',
                            icono: 'fas fa-info',
                            nombreId: 'idservicios',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id) => {
                                    this.mostrardatosbasicos(id);
                            }
                        },
                    ]  
                    
                },
                {
                    header: 'Expediente',
                    field: 'codigo',
                    sortable: false,
                    class: 'codigo',
                    acciones: [
                        {
                            link: true,
                            tipo: 'default',
                            nombreId: 'idservicios',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id) => {
                                console.log('Editar expediente ' + id);
                                this.$router.push({
                                    name: 'Servicio',
                                    params: {
                                        id: id
                                    }
                                });
                            }
                        },
                    ]  
                },
                {
                    header: 'Fecha de eliminacion',
                    field: 'fecha_eliminacion',
                    sortable: true,
                    class: 'fecha_eliminacion',
                },
                {
                    header: 'Tramitador',
                    field: 'tramitador',
                    sortable: false,
                    class: 'tramitador',
                },
                {
                    header: 'Tipo de servicio',
                    field: 'tipos_servicio',
                    sortable: false,
                    class: 'tiposservicio',
                },
                {
                    header: 'Pendiente de',
                    field: 'pendiente',
                    sortable: false,
                },
                {
                    header: 'Tipo',
                    field: 'tipo_dano',
                    sortable: false,
                },
                {
                    header: 'Días Apertura',
                    field: 'dias_apertura',
                    sortable: false,
                },
                {
                    header: 'Días Sin Actualizar',
                    field: 'dias_sin_actualizar',
                    sortable: false,
                },
            
                {
                    header: 'F./P.',
                    field: 'facturapresupuesto',
                    sortable:false,
                },
                {
                    header: 'Estado',
                    field: 'estado',
                    sortable: false,
                },
                {
                    data: null,
                    acciones: [
                        {
                            nombre: 'Editar',
                            class: 'editar',
                            link: true,
                            tipo: 'default',
                            nombreId: 'idservicios',
                            
                            icono: 'far fa-edit',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id) => {
                                console.log('Editar expediente ' + id);
                                this.$router.push({
                                    name: 'Servicio',
                                    params: {
                                        id:id
                                    }
                                });
                            }
                        },
                        {
                            nombre: 'Eliminar',
                            class: 'eliminar',
                            tipo: 'danger',
                            nombreId: 'idservicios',
                            nombreIddos: 'codigo',

                            icono: 'far fa-trash-alt',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id,codigo) => {
                                if (confirm('Desea eliminar el servicio ' + codigo)) {
                                    
                                    this.eliminarservicio(id);
                                }
                            }
                        },
                        {
                            nombre: 'Recuperar',
                            class: 'recuperar',
                            tipo: 'success',
                            nombreId: 'idservicios',
                            nombreIddos: 'codigo',
                            icono: 'fa fa-recycle',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id,codigo) => {
                                if (confirm('Desea recuperar el servicio ' + codigo)) {
                                    this.recuperarservicio(id);
                                    
                                }
                            }
                        },       
                    ]
                }
            ],
            columnasfinalizados: [
                {
                    field: 'icono',
                    acciones: [
                        {
                            link: true,
                            tipo: 'default',
                            icono: 'fas fa-info',
                            nombreId: 'idservicios',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id) => {
                                this.mostrardatosbasicos(id);
                            }
                        },
                    ]

                },
                {
                    header: 'Expediente',
                    field: 'codigo',
                    sortable: false,
                    class: 'codigo',
                    acciones: [
                        {
                            link: true,
                            tipo: 'default',
                            nombreId: 'idservicios',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id) => {
                                console.log('Editar expediente ' + id);
                                this.$router.push({
                                    name: 'Servicio',
                                    params: {
                                        id: id
                                    }
                                });
                            }
                        },
                    ]
                },
                
                {
                    header: 'Tramitador',
                    field: 'tramitador',
                    sortable: false,
                    class: 'tramitador',
                },
                {
                    header: 'Tipo de servicio',
                    field: 'tipos_servicio',
                    sortable: false,
                    class: 'tiposservicio',
                },
                {
                    header: 'F.emision',
                    field: 'fecha_ultima_factura',
                    sortable: true,
                    class: 'fecha_ultima_factura',
                },
                {
                    header: 'F.factura',
                    field: 'fecha_factura',
                    sortable: true,
                    class: 'fecha_factura',
                },
                {
                    data: null,
                    acciones: [
                        {
                            nombre: 'Editar',
                            class: 'editar',
                            link: true,
                            tipo: 'default',
                            nombreId: 'idservicios',

                            icono: 'far fa-edit',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id) => {
                                console.log('Editar expediente ' + id);
                                this.$router.push({
                                    name: 'Servicio',
                                    params: {
                                        id: id
                                    }
                                });
                            }
                        },
                        {
                            nombre: 'Recuperar',
                            class: 'recuperar',
                            tipo: 'success',
                            nombreId: 'idservicios',
                            nombreIddos: 'codigo',
                            icono: 'fa fa-recycle',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id, codigo) => {
                                if (confirm('Desea recuperar el servicio ' + codigo)) {
                                    this.recuperarservicio(id);
                                }
                            }
                        },
                    ]
                },
                {
                    nombre: 'Facturas',
                    class: 'facturas',
                    field:'facturas',
                }
            ]
        }
    },
    methods: {
        //https://sonia.pwgs.app/modulos/pwgsapi/index.php/listados-servicios/activos
        obtenerExpedientesFact(factura, evento){
            this.buscador = factura;
            this.obtenerExpedientes(evento);
        },
        async obtenerExpedientes(event) {
            this.difuminado = "filter:blur(4px)"; 
            
         if(this.opcionseleccionada != undefined){
            let auxcolumnas = {
                header: 'Fecha de eliminacion',
                field: 'fecha_eliminacion',
                sortable: true,
                class: 'fecha_eliminacion',
            };
            event.buscador = this.buscador;
            if (this.compsele) { event.id_compania = this.compsele.idcompañia }
  
             if (this.opcionseleccionada != "Activos") {
                
                this.columnas[11].acciones[1].class = "miclase";
            }
            if (this.opcionseleccionada == "Eliminados") {
                this.columnas[2] = auxcolumnas;

             }
            else {
                this.columnas[11].acciones[1].class = "eliminar"; 
            }
            if (this.opcionseleccionada != "Eliminados") {
                this.columnas[2] = {
                    header: 'Fecha',
                    field: 'fecha',
                    sortable: true,
                    class: 'fecha',
                } ;

                this.columnas[11].acciones[2].class = "miclase";
            }
            else {

                this.columnas[11].acciones[2].class = "recuperar";
            }

            
            event.urgente = this.urgente;
           
            if (this.estadopenselec) {
                event.id_esp_pend = this.estadopenselec.id;
            }
            if (this.tipodanoseleccionado) {
                event.tipo_dano = this.tipodanoseleccionado.danyo;
            }
            if (this.tiposservicioseleccionado) {
                event.id_tipo = this.tiposservicioseleccionado.id;
            }
            if (this.tramitadoreseleccionado) {
                event.id_tramitador = this.tramitadoreseleccionado.id;
            }
            if (this.operarioseleccionado) {
                event.ids_operarios = [this.operarioseleccionado.id];
                console.log('operselec',this.operarioseleccionado);
            }
            if(this.satisfaccionselec){
                event.satisfaccion = this.satisfaccionselec.valor;
            }
            const api = new PwgsApi;
            console.log('evnt', event);
            const aux = await api.post('listados-servicios/' + this.opcionseleccionada.toLowerCase(), event);
            this.expedientes = aux.datos;
            this.total = aux.n_total_registros;
             this.$emit('evento_total_servicios', this.total); //Es llamado por ./Escritorio.vue
             this.difuminado = "";
         }
        },
        async cargartiposdanyo() {
            const api = new PwgsApi();
            const datos = await api.get('tipos-dano');
            this.tiposdano = datos.datos;
        },
        async cargartiposservicio() {
            const api = new PwgsApi();
            const datos = await api.get('tipos-servicio');
            this.tiposservicio = datos.datos;
        },
        async obtenerEstadosPendientes() {
            //GET https://api.pwgs.apuyen/modulos/pwgsapi/index.php/tipos-estado-pendiente
            const api = new PwgsApi();
            let aux = await api.get('listados-servicios/estados-pendientes');
            for(let [id,dat] of Object.entries(aux.datos)){
                var est = {"id":id, "nombre":dat};
                this.estadospendientes.push(est);              
            }
        },
        async mostrardatosbasicos(id) {
            const api = new PwgsApi();
            this.datosbasicosamostrar = await api.get('servicios/' + id + '/datos-basicos');
            this.visible = true;            
        },
        componenteDinamico(ruta) {
            return defineAsyncComponent(() => import(`@/${ruta}`));
        },
        nuevoservicio() {
            this.$router.push({ name: 'Crear Servicio' });
        },
        async eliminarservicio(id) {
            //PUT /servicios/:id/elimina
            const api = new PwgsApi();
        try{
            await api.put('servicios/' + id + '/elimina');
            this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Servicio eliminado correctamente', life: 2000 });
            this.obtenerExpedientes();
        }
        catch (error) {
            this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
        }
        },
        async cargarTramitadores() {
            //GET pwgsapi/index.php/usuarios/tramitadores
            const api = new PwgsApi();
            const datos = await api.get('usuarios/gestion');            
            this.tramitadores = datos.datos;
            let tramitadorcero = { id: "0",nombre:"Sin Tramitador"};
            this.tramitadores.unshift(tramitadorcero);
        },
        async cargarOperarios() {
            //GET pwgsapi/index.php/usuarios/tramitadores
            const api = new PwgsApi();
            const datos = await api.get('operarios/simple?sortField=nombre&sortOrder=1');            
            this.operarios = datos.datos;
            let operariocero = { id: "0",nombre:"Sin Operario"};
            this.operarios.unshift(operariocero);
        },
        async recuperarservicio(id) {
             //PUT /servicios/:id/recupera
            const api = new PwgsApi();
            try{
                await api.put('servicios/' + id + '/recupera');
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Servicio recuperado correctamente', life: 2000 });
                this.$router.push({
                    name: 'Servicio',
                    params: {
                        id: id
                    }
                });            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
        },
        cargarSatisfacciones(){
            this.satisfacciones=[
                {nombre:'0: Neutro', valor:'0'},
                {nombre:'1: Muy insatisfecho', valor:'1'},
                {nombre:'2: Poco satisfecho', valor:'2'},
                {nombre:'3: Satisfecho', valor:'3'},
                {nombre:'4: Muy satisfecho', valor:'4'}
            ]
        }
    },
 
    mounted() {
        this.obtenerEstadosPendientes();
        //this.obtenerExpedientes();
        this.cargartiposdanyo();
        this.cargartiposservicio();
        this.cargarTramitadores();
        this.cargarOperarios();
        this.cargarSatisfacciones();
    },
    watch: {
        satisfaccionselec(){
            if(this.operarioseleccionado){
                this.obtenerExpedientes(this.mievento);
            }else{
                delete this.mievento["satisfaccion"];
                this.obtenerExpedientes(this.mievento);
            }
        },
        opcionseleccionada() {
            this.obtenerExpedientes(this.mievento);


        },

        selectcompany() {
            this.compsele = this.$props.selectcompany;
            
        },
        compsele() {
           if(this.compsele){
               this.obtenerExpedientes(this.mievento);

           }
           else{
               delete this.mievento["id_compania"];
               this.obtenerExpedientes(this.mievento);


           }
          
        },
        estadopenselec() {
            if (this.estadopenselec) {
                this.obtenerExpedientes(this.mievento);

            }
            else {
                delete this.mievento["id_esp_pend"];
                this.obtenerExpedientes(this.mievento);

            }
            //id_esp_pend
        },
        tipodanoseleccionado() {
            if (this.tipodanoseleccionado) {
                this.obtenerExpedientes(this.mievento);

            }
            else {
                delete this.mievento["tipo_dano"];
                this.obtenerExpedientes(this.mievento);


            }
        },
        tiposservicioseleccionado() {
            if (this.tiposservicioseleccionado) {
                this.obtenerExpedientes(this.mievento);

            }
            else {
                delete this.mievento["id_tipo"];
                this.obtenerExpedientes(this.mievento);

            }
        },
        operarioseleccionado() {
            if(this.operarioseleccionado){
                this.obtenerExpedientes(this.mievento);
            }else{
                delete this.mievento["ids_operarios"];
                this.obtenerExpedientes(this.mievento);
            }
        },
        tramitadoreseleccionado() {
            if (this.tramitadoreseleccionado) {
                this.obtenerExpedientes(this.mievento);

            }
            else {
                delete this.mievento["id_tramitador"];
                this.obtenerExpedientes(this.mievento);

            }
        }
    },
    computed: {
    }
}
</script>
<style>
.p-dialog {
    width: 90%;
}
.p-datatable-header{
    visibility:hidden;
}
.ui-datatable tr.ui-datatable-even:hover, .ui-datatable tr.ui-datatable-odd:hover ,.p-datatable-tbody tr:hover,.codigo:hover {

}
.miclase{
display: none;
}
.Gris{
    background-color: #6c757d !important;
    border-color: #6c757d !important;
}
.Verde{
    background: #28a745 !important;
    border-color: #28a745 !important;
}
.Rojo{
    background-color: #e20909 !important;
    border-color: #e20909 !important;
}
.Amarillo{
    background-color: #ffcc00 !important;
    border-color: #ffcc00 !important;
}
.Naranja {
    background-color: #f97316 !important;
    border-color: #f97316 !important;
}
.Todos{
    Background-color: #007bff !important;
    border-color: #007bff !important;
}
.codigo{
    cursor: pointer !important;
}
.acciones{
    cursor: pointer !important;
}
.p-selectbutton .p-button.p-highlight{
    background: #007bff !important;
    border-color: #007bff !important;
    color: white !important;
}
.p-selectbutton .p-button{
    background: #f4f6f9 !important;
    border-color: grey !important;
    color: black !important;
}
.p-dropdown-panel {
    border: 1px solid;
    color: #c1c1c1;
}
.pi-filter-icon {
    display: none !important;
}

.p-column-filter-menu-button.p-link {
    display: none !important;
}

.p-column-filter-menu-button.p-column-filter-menu-button-active {
    display: none !important;
}
.loader {
    border: 16px solid #f3f3f3;
    /* Light grey */
    border-top: 16px solid #3498db;
    /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>